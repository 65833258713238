#booking-hero {
  background-image: url("/img/tbl-mountain.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width:100%;
}
.logo-container {
  margin-top:auto;
  margin-bottom:auto;
}
.logo {
  margin:5px;
  max-height: 45px;
}