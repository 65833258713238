.carousel-indicators {
  position: unset;
  margin-left: 0;
  margin-right: 0;
  cursor: grab;

}
.carousel-indicators span {
  width: 82px;
  text-indent: 0;
}
.carousel-indicators span img {
  min-width: 105px;
  max-width: 110px;
}

.carousel-inner {
  min-height: 300px;
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .5s;
  transition-property: opacity;
}

.carousel-fade  .carousel-item.active,
.carousel-fade  .carousel-item-next.carousel-item-left,
.carousel-fade  .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade  .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}

.icon-wrapper {
  background-color: $gray-300;
}
.carousel-control-next, .carousel-control-prev {
  width:45px;
}

option.place-divider {
  background-color: $gray-600;
  color: $white;
}

#rental-form #step-two, #rental-form #step-three {
  display: none;
}
.tab-inactive {
  display: none !important;
}
.tab-active {
  display: block !important;
}

#rental-form select {
  background-image:
          linear-gradient(45deg, transparent 50%, $gray-800 60%),
          linear-gradient(135deg, $gray-800 40%, transparent 50%) !important;
  background-position:
          calc(100% - 30px) 14px,
          calc(100% - 20px) 14px,
          100% 0;
  background-size:
          10px 10px,
          10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.carousel-control-next, .carousel-control-prev {
  margin-top:65px;
}

.feedback hr {
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}