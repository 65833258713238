.rates .nav-item {
  background-color: $primary;
  border: solid 1px #fff3cd;
}
.rates .nav-tabs .nav-link.active, .rates .nav-tabs .nav-link .active {
  background-color: $success;
}
.rates .nav-link {
  color: #ffffff !important;
}
.rates thead, .fleet table thead {
  background-color: $primary;
  color: #ffffff !important;
}
.rates table {
  margin-top:20px;
}
.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c;
}
.w-33 {
  width: 33.33%;
}