.move-top {
  position: fixed;
  bottom: 50px;
  right: 100px;
  display: none;
  width:40px;
}
.footer-social {
  color: $gray-600;
}
footer li {
  color: $gray-200;
}