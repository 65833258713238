.hidden {
  visibility:hidden;
}
.gone {
  display: none;
}
small {
  color: $success;
  line-height: 1em;
}

.margin-center {
  margin-left:auto;
  margin-right:auto;
}
